import React from "react"
import { Typography } from "@material-ui/core"

import { useStyles } from "./styles"

const title = "We have a proven track record of delivering sustained value that results in:"

const records = [
  "Improved data quality and increased transparency",
  "More efficient business processes and higher employee productivity",
  "Reduced development and operations costs for managing data",
  "Faster response to business and technology changes",
  "Ability to comply with and track compliance with mandates and regulations",
]

const TrackRecord = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <ul className={classes.list}>
        {records.map((item, index) => (
          <li key={`track-record-item-${index}`}>
            <Typography variant="body1" className={classes.item}>
              {item}
            </Typography>
          </li>
        ))}
        </ul>
      </div>
    </div>
  )
}

export default TrackRecord
