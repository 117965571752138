import biverPhoto from "../../../../media/about-us/biver.png"
import lambPhoto from "../../../../media/about-us/lamb.png"
import dziadPhoto from "../../../../media/about-us/dziad.png"
import turnerPhoto from "../../../../media/about-us/turner.png"
import rabusPhoto from "../../../../media/about-us/rabus.png"

export const leadersList = [
  {
    name: "Matt Biver",
    position: "Chief Executive Officer",
    email: "matt.biver@grandviewanalytics.com",
    linkedIn: "https://www.linkedin.com/in/matthewbiver/",
    intro: [
      "Matt is CEO at Grandview. With nearly 20 years of experience in the financial industry, Matt has developed a deep expertise designing and implementing data management and business process solutions for buy-side investment managers and financial service providers.",
      "Prior to forming Grandview, Matt spent his career working in structured finance, hedge fund administration, middle office outsourcing, and data strategy consulting at LaSalle Bank, Bank of America, Cortland Capital Market Services, and Guggenheim Partners. Matt graduated from the University of Iowa with a BBA in Finance in 2001."
    ],
    photo: biverPhoto
  },
  {
    name: "Chris Lamb, CFA",
    position: "President, Consulting Services",
    email: "chris.lamb@grandviewanalytics.com",
    linkedIn: "https://www.linkedin.com/in/cdlamb/",
    intro: [
      "Chris is President, Consulting Services at Grandview. With nearly 20 years of experience in the financial industry, Chris has developed a deep expertise in financial modeling, portfolio risk management, and process optimization for buy-side investment managers and financial service providers.",
      "Prior to forming Grandview, Chris spent his career working in structured finance analytics and alternative asset portfolio risk management in roles at Bank of America, Gemini Alternative Funds, and Alphametrix. Chris graduated from the University of Rochester with a BA in Economics in 2002 and is a CFA Charterholder."
    ],
    photo: lambPhoto
  },
  {
    name: "Reid Turner",
    position: "Chief Technology Officer, Data Services",
    email: "reid.turner@gvtsolutions.com",
    linkedIn: "https://www.linkedin.com/in/reid-turner-7019625/",
    intro: [
      "Reid is Chief Technology Officer, Technology and Data Services at Grandview. With 25 years of experience in the financial industry, Reid leverages cutting edge technologies to provide innovation and technology leadership for Grandview’s clients.",
      "Prior to joining Grandview, Reid was the Fixed Income CTO for Neuberger Berman and was the chief architect who designed proprietary technologies supporting front and back office operations and data management. Prior to Neuberger, Reid provided technology leadership at Lincoln Capital Management, worked in telecommunications research, and taught software development at the University of Colorado. Reid graduated from the University of North Carolina at Chapel Hill with a BA in English and Economics and holds a Ph.D. in Computer Science from University of Colorado Boulder."
    ],
    photo: turnerPhoto
  },
  {
    name: "Greg Dziad",
    position: "Chief Operating Officer, Data Services",
    email: "greg.dziad@gvtsolutions.com",
    linkedIn: "https://www.linkedin.com/in/greg-dziad-79a5343/",
    intro: [
      "Greg is Chief Operations Officer, Technology and Data Services at Grandview. Greg is a solutions-driven technology leader with over 20 years of experience in the financial industry.",
      " Throughout Greg’s career in financial services, he has delivered technology products and services for large-scale technology platforms and software development teams supporting a combined $500 Billion in Assets under Management while working at Mariana Systems, Neuberger Berman, Lehman Brothers Asset Management, and Lincoln Capital Management. Through this experience, Greg has developed a disciplined approach for rapid software development through the agile development process. Greg has both a BS degree in Criminal Justice from The University of Dayton and a BS in Business Administration from Lourdes University."
    ],
    photo: dziadPhoto
  },
  {
    name: "Charles \"Chip\" Rabus",
    position: "Managing Director, Business Development",
    email: "charles.rabus@grandviewanalytics.com",
    linkedIn: "https://www.linkedin.com/in/charles-a-rabus-7325a131/",
    intro: [
      "Chip Rabus is Managing Director of Business Development at Grandview, where he drives the company’s sales and marketing efforts. Chip has more than 20 years of client service and sales leadership experience in the financial services, data, and technology industries.",
      "Prior to joining Grandview, Chip spent the majority of his career in sales and leadership positions at Bloomberg LP where he developed a rich knowledge of financial data, desktop analytics terminals, and order management systems. Most recently, Chip led sales efforts at alternative data provider Edison Software. Chip also has experience in fixed income brokerage and trading. Originally from New York, Chip now makes his home in Chicago. He holds an MBA in Investment Management from Pace University and a BA in American History from Stony Brook University."
    ],
    photo: rabusPhoto
  },
]
