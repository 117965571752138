import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    margin: "20px 0",
    fontWeight: 500,
    marginBottom: 30
  },
  list: {
    listStyleType: "square",
    fontSize: "1.5rem"
  },
  item: {
    marginBottom: 20,
  },
}))
