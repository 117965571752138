import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "0 60px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    textAlign: "center",
    marginBottom: 60,
    marginTop: 50,
    fontWeight: 500,
    maxWidth: 850
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 60,
  },
  iconWrapper: {
    height: 120,
    marginBottom: 20,
  },
  icon: {
    width: 120,
    height: 120,
  },
  title: {
    maxWidth: 300,
    color: "#4d4d4d",
    fontFamily: "Roboto Bold",
    lineHeight: 1.2,
    textAlign: "center",
  },
  itemDescription: {
    color: "#4d4d4d",
    textAlign: "left",
    lineHeight: 1.5,
    fontSize: "1.25rem",
    maxWidth: 375,
    marginTop: 10,
  }
}))
