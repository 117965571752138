import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    maxWidth: "1440px"
  },
  description: {
    textAlign: "left",
    marginBottom: 60
  },
  title: {
    textAlign: "center",
    margin: 30,
  },
  personContainer: {
  },
  person: {
    "& :hover": {
      cursor: "pointer"
    }

  },
  itemInnerWrapper: {
    height: "100%",    
    background: "#f6f6f6",
    "&:hover": {
      boxShadow: theme.shadows[3],
    }
  },
  leaderName: {
    fontSize: "1.25rem"
  },
  leaderPosition: {
    fontSize: ".8rem"
  },
  iconButton: {
    color: "#000",
    padding: 0,
    marginLeft: 10
  },
  iconButtonSvg: {
    
  },
  avatarWrapper: {
    position: "relative",
    width: "100%",
    height: 0,
    paddingTop: "100%",
  },
  avatarImageWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "center"
  },
  avatarImage: {
    width: "100%",
  },
  itemFooter: {
    padding: 10,
  },
  itemEmail: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#2b67b1",
    fontSize: ".8rem"
  },
}))
