import businessIcon from "../../../../media/about-us/business-icon.png"
import financeIcon from "../../../../media/about-us/finance-icon.png"
import technologyIcon from "../../../../media/about-us/technology-icon.png"

export const benefitsList = [
  {
    icon: businessIcon,
    title: "Business",
    description: "Navigating complex organizations and business functions",
  },
  {
    icon: financeIcon,
    title: "Finance",
    description: "Deep domain expertise across asset classes and investment vehicle types",
  },
  {
    icon: technologyIcon,
    title: "Technology",
    description: "Deploying technology to solve complex business problems",
  },
]
