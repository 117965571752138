import React from "react"

import { useStyles } from "./styles"
import { Typography } from "@material-ui/core"

const paragraph1 = "Founded in 2014, Grandview is a technology consulting and managed data services company that empowers financial institutions with nimble technology, efficient business processes, and clear insights about their businesses, markets, and clients."
const paragraph2 = "Grandview helps financial institutions optimize processes, technology, and data across all functions of their businesses to accelerate decision-making and drive growth. We are a trusted strategy and delivery partner, committed to doing what is best for our clients with integrity and diligence."
const introduction = [
  paragraph1,
  paragraph2
]

const CompanyIntroduction = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        {introduction.map((item, index) => (
          <Typography key={`company-introduction-${index}`} variant="body2" className={classes.description}>
            {item}
          </Typography>
        ))}
      </div>
    </div>
  )
}

export default CompanyIntroduction
