import React from "react"
import { Typography, Grid } from "@material-ui/core"

import { benefitsList } from "./data"
import { useStyles } from "./styles"

const description = "Our clients benefit from our unique blend of technology, business, and financial industry expertise."

const Benefits = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="h4" className={classes.description}>
          {description}
        </Typography>
        <Grid container spacing={10}>
        {benefitsList.map((item, index) => (
          <Grid
            key={`benifits-item-${index}`}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            className={classes.item}
          >
            <div className={classes.iconWrapper}>
              <img src={item.icon} className={classes.icon} />
            </div>
            <Typography variant="subtitle2" className={classes.title}>
              {item.title}
            </Typography>
            <Typography variant="body1" className={classes.itemDescription}>
              {item.description}
            </Typography>
          </Grid>
        ))}
        </Grid>
      </div>
    </div>
  )
}

export default Benefits
