import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import CompanyIntroduction from "../sections/about-us/CompanyIntroduction"
import Benefits from "../sections/about-us/Benefits"
import TrackRecord from "../sections/about-us/TrackRecord"
import Leaders from "../sections/about-us/Leaders"
import aboutUsHeroBgUrl from "../../media/about-us-banner-overlay.png"

const aboutUsHeroTitle = "About Us"
const aboutUsHeroDescription = ""

const AboutUsPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title="About Us" />
    <Hero
      backgroundImage={aboutUsHeroBgUrl}
      title={aboutUsHeroTitle}
      description={aboutUsHeroDescription}
    />
    <CompanyIntroduction />
    <Benefits />
    <TrackRecord />
    <Leaders />
  </Layout>
)

export default AboutUsPage
