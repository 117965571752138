import * as React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import { Typography, Grid, Link, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { useStyles } from "./styles"

interface BioDialogProps {
  open: boolean
  handleClose: Function
  bio: BioProps
}

interface BioProps {
  name: String
  position: String
  intro: String[]
  photo: string
  email: string
}

const BioDialog = ({ open, handleClose, bio }: BioDialogProps) => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth={false}
      maxWidth="md"
      open={open}
      onClose={() => handleClose()}
    >
      <IconButton aria-label="close" className={classes.closeIcon} onClick={() => handleClose()}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container className={classes.bioGrid}>
          <Grid item xs={12} sm={12} md={4} className={classes.bioInfo}>
            <div className={classes.imgWrapper}>
              <img src={bio.photo} />
            </div>
            <Typography variant="body1" className={classes.bioName}>
              {bio.name}
            </Typography>
            <Typography variant="body2" className={classes.bioPosition}>
              {bio.position}
            </Typography>
            <Link href={`mailto:${bio.email}`}>
              <Typography variant="body2" className={classes.itemEmail}>
                {bio.email}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            {bio.intro &&
              bio.intro.map((bItem, bIndex) => (
                <Typography
                  key={`bio-intro-${bItem}`}
                  variant="body2"
                  className={classes.bioIntro}
                >
                  {bItem}
                </Typography>
              ))}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default BioDialog
