import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bioGrid: {
      "& > *": {
        padding:"35px 15px"
      }
    },
    closeIcon: {
      position: "absolute",
      right: "0px",
      zIndex: 9999,
      padding: 15
    },
    bioInfo: {
      textAlign: "center"
    },
    bioName: {
      fontSize: "1rem",
      marginTop: 5
    },
    bioPosition: {
      fontSize: ".8rem",
    },
    itemEmail: {
      fontSize: ".8rem"
    },
    bioIntro: {
      fontSize: "1rem",
      marginBottom: 10,
    },
    imgWrapper: {
      "& > img": {
        maxWidth: "100%",
        maxHeight: 250
      }
    },
    avatarImageWrapper: {

    }
  }),
)
