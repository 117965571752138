import * as React from "react"
import { Typography, Grid, Link, IconButton } from "@material-ui/core"
import LinkedInIcon from "@material-ui/icons/LinkedIn"

import BioDialog from "../../../components/Dialog"

import { leadersList } from "./data"
import { useStyles } from "./styles"

const description =
  "Our team includes industry leaders who have a strong combination of business, technology, and finance expertise across asset classes and investment vehicle types. We have managed business and enterprise technology teams in support of the front, middle, and back office for small start-ups, top-tier investment managers, banks, and everything in between."
const title = "Meet Our Leadership Team"

const Leaders = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [bio, setBio] = React.useState({})

  const handleOpen = (bioItem: any) => {
    setBio(bioItem)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setBio({})
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.description}>
          {description}
        </Typography>

        {/* <div className={classes.personContainer}> */}
        <Grid container spacing={5} className={classes.personContainer}>
          {leadersList.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              md
              key={`leader-item-${index}`}
              className={classes.person}
            >
              <div
                className={classes.itemInnerWrapper}
                onClick={() => handleOpen(item)}
              >
                <div className={classes.avatarWrapper}>
                  <div
                    className={classes.avatarImageWrapper}
                    style={{
                      backgroundImage: `url(${
                        item.photo || "https://dummyimage.com/300x300/ddd"
                      })`,
                    }}
                  />
                </div>
                <div className={classes.itemFooter}>
                  <Typography
                    variant="subtitle2"
                    className={classes.leaderName}
                  >
                    {item.name}
                    <IconButton
                      key={`leader-item-linkedin-${index}`}
                      aria-label="Linkedin.com"
                      className={classes.iconButton}
                      onClick={() => window.open(item.linkedIn)}
                    >
                      <LinkedInIcon
                        fontSize="small"
                        htmlColor="#0e76a8"
                        className={classes.iconButtonSvg}
                      />
                    </IconButton>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.leaderPosition}
                  >
                    {item.position}
                  </Typography>
                  <Link href={`mailto:${item.email}`}>
                    <Typography variant="body2" className={classes.itemEmail}>
                      {item.email}
                    </Typography>
                  </Link>
                </div>
              </div>
              
            </Grid>
          ))}
        </Grid>
        <BioDialog open={open} handleClose={handleClose} bio={bio} />
        {/* </div> */}
      </div>
    </div>
  )
}

export default Leaders
